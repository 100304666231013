<template>
  <div class="outer_page_content">
    <my-bread-crumb :breadMenu="breadMenu"></my-bread-crumb>
    <div class="reading_top_container">
      <reading-info :info="readDetail"></reading-info>
    </div>
    <div class="reaidng_middle_container"></div>
    <div class="reading_middle_container">
      <component
        v-if="currentComponent"
        :itemList="itemList"
        :bookId="urlData.bookId"
        :chapterId="urlData.chapterId"
        :typeId="urlData.typeId"
        :gameId="gameId"
        :is="currentComponent"
        @endClick="endConfirm"
      ></component>
    </div>
  </div>
</template>

<script>
import { fetchGameTaskInfo, fetchBookGameList } from '@/api/service';
import MyBreadCrumb from '@/components/BreadCrumb';
import ReadingInfo from '@/components/ReadingInfo';
import { showMySuccess } from '../../../utils/common';
import globalConfig from '@/store/global_config';

export default {
  components: {
    MyBreadCrumb,
    ReadingInfo
  },
  data() {
    return {
      // 地址栏的数据
      urlData: this.$route.params,
      // 问题列表
      itemList: [],
      // 游戏列表
      gameList: [],
      gameId: '',
      currentGameIndex: 0,
      // 阅读详情
      readDetail: {},
      // 当前组件
      currentComponent: '',
      // 面包屑导航
      breadMenu: [
        {
          title: '选择教材',
          path: '/book'
        },
        {
          title: '教材详情',
          isBack: true
        },
        {
          title: '学习详情'
        }
      ],
      // 当前完成到第几题
      currentIndex: 0,
      // 按钮加载中的样式
      btnLoading: false
    };
  },
  methods: {
    fetchGameList() {
      fetchBookGameList({
        bookId: this.bookId,
        bookChapterId: this.chapterId,
        type: this.typeId
      }).then(response => {
        const { data } = response;
        const newGameList = this.filterOneRate(data);
        this.gameList = newGameList;
        globalConfig.commit(
          'changeFirstGame',
          newGameList.length === data.length
        );
        if (this.gameList.length === 0) {
          this.hasFinished();
          return;
        }
        this.fetchGameInfo();
      });
    },

    // 已经完成
    hasFinished() {
      showMySuccess('你已经学完', () => {
        window.history.back();
      });
    },

    filterOneRate(arr) {
      const newArr = arr.filter(item => Number(item.rate) < 1);
      return newArr;
    },

    // 获取游戏详情
    fetchGameInfo() {
      const { id: gameId, template_path: templatePath } =
        this.gameList[this.currentGameIndex] || {};
      this.gameId = String(gameId);
      fetchGameTaskInfo({
        bookId: this.bookId,
        bookChapterId: this.chapterId,
        typeId: this.typeId,
        gameId
      }).then(response => {
        const { data = {} } = response || {};
        this.readDetail = data;
        this.itemList = data.questions || [];
        this.templatePath = templatePath;
        const componetPathObj = {
          'topic-reading': 'Study/Read/topicReading',
          'cloze-test': 'Study/Read/clozeTest',
          'topic-writing': 'Study/Read/topicWriting'
        };
        this.currentComponent = require('@/components/' +
          componetPathObj[this.templatePath]).default;
      });
    },

    endConfirm() {
      globalConfig.commit('changeFirstGame', false);
      if (this.currentGameIndex + 1 >= this.gameList.length) {
        this.hasFinished();
        return;
      }
      this.currentGameIndex += 1;
      this.fetchGameInfo();
    }
  },

  mounted() {
    const { bookId, chapterId, typeId } = this.urlData;
    this.bookId = bookId;
    this.chapterId = chapterId;
    this.typeId = typeId;
    this.fetchGameList();
  }
};
</script>

<style lang="less" scoped>
.outer_page_content {
  overflow: hidden;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .reading_top_container {
    height: 45%;
    background-color: white;
    overflow: scroll;
  }
  .reaidng_middle_container {
    height: 1px;
    border: solid 1px #ddd;
  }
  .reading_middle_container {
    flex: 2;
    background-color: white;
    position: relative;
    overflow-y: scroll;
  }
}
</style>
